import { render, staticRenderFns } from "./EndsWith.vue?vue&type=template&id=6595fd38&scoped=true&"
import script from "./EndsWith.vue?vue&type=script&lang=ts&"
export * from "./EndsWith.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6595fd38",
  null
  
)

export default component.exports