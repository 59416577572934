







import BaseStringList from './components/BaseStringList.vue'
import InvertCondition from './components/InvertCondition.vue'
import { EndsWithCondition } from '@/includes/types/PmConfig.types'

import Vue from 'vue'
import { Component, VModel } from 'vue-property-decorator'

@Component({
  components: { BaseStringList, InvertCondition }
})
export default class EndsWith extends Vue {
  @VModel() model!: EndsWithCondition
}
